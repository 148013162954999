module.exports = [{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":2,"matomoUrl":"https://matomo.statewatch.org/matomo","siteUrl":"https://opensecuritydata.eu","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js"},
    }]
